<template>
  <div class="BGS">
      <Header></Header>
      <!-- <div class="title">{{name}}</div> -->
    <div class="content" v-html="content" >
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Axios from "axios";
import {about} from "../api/api"
export default {
    data(){
        return{
            content:'',
            types:'',
            name:'',
        }
    },
  components: {
    Header
  },
    methods:{
        About(){
          Axios({
        method: "get",
        url: "https://tsadmin.qudong.com/api/about",
       params: {
          type:this.types
        },
      }).then((res) => {
         if(res.data.code ==200){
        this.content=res.data.data.content
        }
      });
      //   about({type:this.types }).then((res) => {
      //   if(res.data.code ==200){
      //   this.content=res.data.data.content
      //   }
      // });
        },
    },
    mounted(){
    this.name=this.$route.query.name;
    this.types=this.$route.query.type;
    this.About();
    },
    
}
</script>

<style scoped>
.BGS{
    position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}
.title{
    width:1200px;
    margin:20px auto;
    font-size:22px;
    font-weight:bold
}
.content{
    width:1200px;
    margin:20px auto;
    min-height: 1000px;
    background-color: #f5f5f5;
}
</style>